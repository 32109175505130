import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  id: -1,
  username: '',
  boardsCount: 0,
  loggedIn: true,
  mentions: { mentions: [], actionItemsMentions: [] },
  actionItems: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
