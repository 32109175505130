var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col main" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row subheader" }, [
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.$t("signin.textFirstPart")) }
      }),
      _vm._v(" "),
      _c("strong", {
        domProps: { innerHTML: _vm._s(_vm.$t("signin.textBrightPart")) }
      }),
      _vm._v(" "),
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.$t("signin.textLastPart")) }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("a", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "signin.button",
            expression: "'signin.button'"
          }
        ],
        staticClass: "signin",
        attrs: { href: "/api/auth/google", rel: "noopener" }
      })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row logo" }, [
      _c("span", { staticClass: "yellow" }, [_vm._v("My")]),
      _vm._v("Retro\n  ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }