import axios from 'axios';

const actions = {
  setLoggedIn({ commit }, value) {
    commit('setLoggedIn', value);
  },
  logout({ commit }) {
    return axios.get('/api/logout')
      .then(() => {
        commit('app/switchTheme', { isDarkMode: true }, { root: true });
        localStorage.clear();
        commit('setLoggedIn', false);
      });
  },
  getUserInfo({ commit }) {
    return axios.get('/api/me')
      .then((response) => {
        commit('setUser', response);
        commit('setLoggedIn', true);

        return response;
      });
  },

};

export default actions;
