var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "burger-container" }, [
    _c("input", {
      ref: "checkbox",
      staticClass: "hamburger-checkbox",
      attrs: { type: "checkbox" },
      on: { click: _vm.onClick }
    }),
    _vm._v(" "),
    _c("span", { class: ["stick"].concat(_vm.themeClasses) }),
    _vm._v(" "),
    _c("span", { class: ["stick"].concat(_vm.themeClasses) }),
    _vm._v(" "),
    _c("span", { class: ["stick"].concat(_vm.themeClasses) })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }