<template>
  <div>
    <header :class="headerThemeClasses">
      <header-layout/>
    </header>
    <div :class="['container-wrapper', containerWrapperThemeClasses]">
      <div class="notification-container">
        <notification-item
          v-for="notification in notificationList"
          :key="notification.id"
          :notification="notification"
          class="notification-item"
        />
      </div>
      <div class="container">
        <article
          ref="article"
          class="container__article"
        >
          <transition name="expand">
            <v-side-bar
              v-if="loggedIn && sidebarToggled.sidebarToggled"
              :footer-rect="footerRect"
              :class="['sidebar-container', { 'sidebar-toggled': sidebarToggled.sidebarToggled }]"
            />
          </transition>
          <router-view
            v-if="loggedIn"
            :key="$route.path"
            class="content"
          />
          <sign-in-layout v-else/>
        </article>
      </div>
    </div>
    <footer
      ref="footer"
      :class="footerThemeClasses"
    >
      <footer-layout/>
    </footer>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import FooterLayout from './FooterLayout.vue';
  import HeaderLayout from './HeaderLayout.vue';
  import SignInLayout from './SignInLayout.vue';
  import { connect, disconnect } from '../../websocket';

  export default {
    name: 'Layout',
    components: {
      HeaderLayout,
      FooterLayout,
      SignInLayout,
      NotificationItem: () => import('../notification/NotificationItem'),
      VSideBar: () => import('../VSideBar'),
    },
    data() {
      return {
        storeUnsubscribeFn: null,
        footerMutationObserver: null,
        footerRect: null,
      };
    },
    computed: {
      ...mapState('user', ['loggedIn']),
      ...mapState('app', [
        'notifications',
        'sidebarToggled',
        'isDarkMode',
      ]),
      headerThemeClasses() {
        return this.isDarkMode ? ['app-header'] : ['app-header', 'app-header-light'];
      },
      containerWrapperThemeClasses() {
        return this.isDarkMode || ['container-wrapper-light'];
      },
      footerThemeClasses() {
        return this.isDarkMode ? ['footer'] : ['footer', 'footer-light'];
      },
      notificationList() {
        return this.notifications.slice().sort((not1, not2) => not1.id - not2.id);
      },
    },
    beforeCreate() {
      const isDarkModeClientValue = localStorage.getItem('isDarkMode');
      let isDarkMode = null;

      if (isDarkModeClientValue === 'true') {
        isDarkMode = true;
        this.$store.commit('app/switchTheme', { isDarkMode });
      } else if (isDarkModeClientValue === 'false') {
        isDarkMode = false;
        this.$store.commit('app/switchTheme', { isDarkMode });
      }

      this.$store.dispatch('user/getUserInfo').then(({ isDarkMode: backendValue }) => {
        if (isDarkMode !== backendValue) this.$store.commit('app/switchTheme', { isDarkMode: backendValue });
      }).catch(this.$notification.notifyError);
    },
    async created() {
      connect('/', '');

      await Promise.all([
        this.setUnreadMentionsCount(),
        this.setUnreadActionItemsCount(),
      ]);

      this.storeUnsubscribeFn = this.$store.subscribe((mutation, state) => {
        const mutationTypeNotRelatedToMention = mutation.type !== 'mentions/increaseUnreadMentionsCount' &&
          mutation.type !== 'mentions/increaseUnreadActionItemsCount';

        if (mutationTypeNotRelatedToMention) {
          return;
        }
        if (mutation.payload.isActionItemMention) {
          this.$notification.notifyInfo(this.$t('newMention.notificationActionItemMessage'));

          return;
        }

        this.$notification.notifyInfo(this.$t('newMention.notificationMereMessage'));
      });
    },
    mounted() {
      this.footerMutationObserver = new MutationObserver(() => {
        this.footerRect = this.$refs.footer.getBoundingClientRect();
      });
      this.footerMutationObserver.observe(
        this.$refs.article,
        { childList: true },
      );
    },
    beforeDestroy() {
      disconnect('/', '');
      this.storeUnsubscribeFn();
      this.destroyFooterMutationObserver();
    },
    methods: {
      ...mapActions('mentions', ['setUnreadActionItemsCount', 'setUnreadMentionsCount']),
      destroyFooterMutationObserver() {
        if (this.footerMutationObserver) {
          this.footerMutationObserver.disconnect();
          this.footerMutationObserver = null;
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import "~/style/styleguide.sass"

  @include expand-keyframes($sidebar-width, sidebar)

  .expand-enter-active, .expand-leave-active
    transition: all 0.3s ease-in-out

  .expand-enter-active
    animation: expand-sidebar .3s

  .expand-leave-active
    animation: expand-sidebar .3s reverse

  .expand-enter, .expand-leave-to
    width: 0

  .app-header
    flex-direction: column
    flex: 0 0 auto
    position: sticky
    z-index: $header-z-index
    top: 0
    height: base-unit($header-height)
    background-color: $bg-main
    border-bottom: 1px solid $hr-color

    &-light
      background: $alabaster-color
      color: rgba($bg-main, 0.5)
      border-bottom: 1px solid $geyser-color

  .footer
    flex: 0 0 auto
    min-height: base-unit($footer-height)
    border-top: 1px solid $hr-color

    &-light
      border-top: 1px solid $geyser-color

  .container-wrapper
    font-size: $base-font-size
    min-height: calc(100vh - #{base-unit($header-height)} - #{base-unit($footer-height)} - #{base-unit(3)})
    background-color: $bg-main
    color: $fg-main
    display: flex
    justify-content: center
    +main-font()
    padding: 0 base-unit(30)
    +large-devices
      padding: 0 base-unit(50)

    &-light
      background-color: $tutu-color

  .container
    display: flex
    flex: auto
    flex-direction: column
    max-width: $max-content-width
    min-height: 100%

    &__article
      flex: 1 1

  .sidebar-container
    position: fixed
    margin-left: base-unit(-30)
    width: $sidebar-width
    background-color: $bg-main
    box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.25)
    z-index: $sidebar-z-index
    +large-devices
      margin-left: base-unit(-50)

    &-light
      box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.25)

  .notification-item
    margin-top: base-unit(13)

  .notification-container
    position: fixed
    right: 0
    z-index: $notification-z-index
</style>
