const mutations = {
  setLoggedIn(state, value) {
    state.loggedIn = value;
  },
  setUser(state, user) {
    state.boardsCount = user.boardsCount;
    state.username = user.username;
    state.id = user.id;
  },
  setBoardsCount(state, value) {
    state.boardsCount += value;
  },
  removeActionItemsNotifications(state) {
    state.mentions = {
      ...state.mentions,
      actionItemsMentions: [],
    };
  },
};

export default mutations;
